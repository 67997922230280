/** @jsx jsx */
import { throttle } from "utils/helpers"
import { useCallback, useEffect, useState } from "react"
import ReactPlayer from "react-player/lazy"
import { animated, useSpring } from "react-spring/web.cjs"
import { Flex, Heading, jsx } from "theme-ui"

interface Props {
  anchorSlug: string
  title: string
  backgroundVideo: any
}

const Hero = ({ anchorSlug, title, backgroundVideo }: Props & any) => {
  const [heroVideoSrc, setHeroVideoSrc] = useState<string>(null)
  const [{ opacity }, set] = useSpring(() => ({
    opacity: 0,
    immediate: true,
  }))

  const onScroll = useCallback(() => {
    const opacity = window.scrollY / (window.innerHeight * 0.8)
    if (opacity >= 0 && opacity <= 1) {
      set({
        opacity: opacity,
      })
    }
  }, [set])

  useEffect(() => {
    setTimeout(() => {
      setHeroVideoSrc(backgroundVideo.url)
    }, 400)
  }, [backgroundVideo.url])

  useEffect(() => {
    window.addEventListener("scroll", throttle(onScroll, 16))

    set({
      opacity: window.scrollY / window.innerHeight,
    })

    return () => {
      window.removeEventListener("scroll", throttle(onScroll, 16))
    }
  }, [onScroll, set])

  return (
    <Flex
      id={anchorSlug}
      as={"section"}
      sx={{
        justifyContent: "center",
        position: "relative",
        width: "100%",
        height: ["80vh", "100vh", "100vh", "100vh"],
        minHeight: "-webkit-fill-available",
        overflow: "hidden",
        background: "#000",
      }}
    >
      <animated.div
        style={{
          willChange: "opacity" as any,
          opacity: opacity as any,
          position: "absolute",
          zIndex: 2 as any,
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          backgroundColor: "black",
        }}
      />
      {heroVideoSrc && (
        <ReactPlayer
          url={heroVideoSrc}
          width="auto"
          height="auto"
          sx={{
            video: {
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              top: 0,
              minWidth: "100%",
              minHeight: "100%",
              width: "auto",
              height: "auto",
              zIndex: 1,
              backgroundSize: "cover",
              overflow: "hidden",
            },
          }}
          playing
          playsInline
          muted
          loop
        />
      )}
      <Flex
        sx={{
          position: "absolute",
          zIndex: 2,
          top: "50%",
          left: "50%",
          transform: "translate3d(-50%,-50%,0)",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Heading
          as={"h1"}
          sx={{
            maxWidth: "600px",
            variant: "styles.hero",
            color: "#fff",
            textAlign: "center",
            px: 3,
            pb: 9,
            position: "relative",
          }}
        >
          {title}
          <div
            sx={{
              m: "0 auto",
              mt: [5, 6, 6],
              width: ["92px", "124px", "183px"],
              height: ["5px", "6px", "7px"],
              backgroundColor: "white",
            }}
          />
        </Heading>
      </Flex>
      <span className={"icon-scroll"} sx={{ bottom: "calc(60px + 30px)" }} />
    </Flex>
  )
}

export default Hero
