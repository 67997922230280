/** @jsx jsx */
import { throttle } from "utils/helpers"
import { useCallback, useEffect, useRef, useState } from "react"
import ReactMarkdown from "react-markdown"
import Slider from "react-slick"
import { animated, config, useSpring } from "react-spring/web.cjs"
import { Shape } from "styles/svg"
import { Container, Flex, jsx } from "theme-ui"
import VideoSlide from "./video-slide"

export interface ExternalVideo {
  id: string
  videoUrl: {
    url: string
    title: string
    provider: string
    providerUid: string
    thumbnailUrl: string
    width: number
    height: number
  }
}

interface Props {
  id: string
  anchorSlug: string
  description: string
  externalVideos: ExternalVideo[]
}

const VideosGallery = ({
  id,
  anchorSlug,
  description,
  externalVideos,
}: Props & any) => {
  const ref = useRef<any>()

  const [{ transform }, set, stop] = useSpring(() => ({
    transform: "translate3d(0px,0px,0)",
    config: config.molasses,
  }))

  const onScroll = useCallback(() => {
    if (!ref.current) {
      return
    }

    const topPos =
      ref.current.getBoundingClientRect().top +
      document.documentElement.scrollTop
    const bottomScroll = window.scrollY + window.innerHeight
    let scroll = (bottomScroll - topPos) * 0.02
    scroll = scroll <= 0 ? 0 : scroll
    set({
      transform: `translate3d(${scroll * 0.2}px, ${scroll - 50}px, 0)`,
    })
  }, [set])

  useEffect(() => {
    window.addEventListener("scroll", throttle(onScroll, 16))

    return () => {
      window.removeEventListener("scroll", throttle(onScroll, 16))
      stop()
    }
  }, [onScroll, set, stop])

  const [focusedIndex, setFocusedIndex] = useState(0)
  const slider = useRef<any>()
  const sliderSettings = {
    infinite: false,
    arrows: false,
    dots: true,
    touchThreshold: 200,
    beforeChange: (oldIndex, newIndex) => setFocusedIndex(newIndex),
    appendDots: dots => (
      <Flex
        as={"ul"}
        sx={{
          listStyleType: "none",
          mx: 5,
          mt: 6,
        }}
      >
        {dots}
      </Flex>
    ),
    customPaging: index => (
      <div sx={{ pb: 5 }}>
        <div
          className={"dot"}
          sx={{
            mr: 4,
            width: "55px",
            height: "7px",
            border: "1px white solid",
            cursor: "pointer",
          }}
        />
      </div>
    ),
  }

  return (
    <Flex
      id={anchorSlug}
      as={"section"}
      ref={ref}
      sx={{
        position: "relative",
        flexDirection: "column",
        backgroundColor: "darkGreen",
        color: "white",
        py: 8,
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <Container
        sx={{
          mx: [-5, -7, -8, -5],
          zIndex: 1,
        }}
      >
        <ReactMarkdown sx={{ mb: 9 }} source={description} />
        <Slider
          ref={slider}
          className={"video-slide"}
          sx={{ mx: -5 }}
          {...sliderSettings}
        >
          {externalVideos.map(({ id, ...video }, index) => (
            <VideoSlide
              key={id}
              {...video}
              inFocus={index === focusedIndex}
              onClick={() => {
                slider.current.slickGoTo(index)
              }}
            />
          ))}
        </Slider>
      </Container>
      <animated.div
        style={{ transform }}
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
        }}
      >
        <Shape
          fill={"#fff"}
          sx={{
            position: "relative",
            top: "50%",
            transform: ["translate3d(-40%,-50%,0)", "translate3d(-50%,-50%,0)"],
            maxHeight: "90%",
            minWidth: "500px",
            zIndex: 0,
            opacity: 0.11,
          }}
        />
      </animated.div>
    </Flex>
  )
}

export default VideosGallery
