/** @jsx jsx */
import { Column, FullContainer, Row } from "components/layout"
import Image, { FluidObject } from "gatsby-image"
import ReactMarkdown from "react-markdown"
import Slider from "react-slick"
import { GalleryArrow } from "styles/svg"
import { Container, Flex, jsx } from "theme-ui"

interface PortfolioItem {
  id: string
  productLine: string
  index: number
  title: string
  description: string
  portfolioImage: {
    title: string
    alt: string
    fluid: FluidObject
  }
  gallery: {
    originalId: string
    title: string
    alt: string
    fluid: FluidObject
  }
}

const arrowStyle = {
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 998,
  cursor: "pointer",
  filter: "drop-shadow(0 2px 5px rgba(0,0,0,0.3))",
}
const NextArrow = props => (
  <Flex {...props} sx={{ ...arrowStyle, right: "20px" }}>
    <GalleryArrow />
  </Flex>
)
const PrevArrow = props => (
  <Flex
    {...props}
    sx={{
      ...arrowStyle,
      left: "20px",
      transform: "translateY(-50%) rotate(180deg)",
    }}
  >
    <GalleryArrow />
  </Flex>
)

interface Props {
  id: string
  anchorSlug: string
  description: string
  portfolioItems: PortfolioItem[]
}

const PortfolioItem = ({
  index,
  title,
  description,
  productLine,
  gallery,
  portfolioImage: { fluid },
}: PortfolioItem & any) => {
  const leftPos = index % 2 === 0
  const sliderSettings = {
    infinite: true,
    dots: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    touchThreshold: 200,
  }

  return (
    <Row
      sx={{
        position: "relative",
        backgroundColor: leftPos ? "light" : "white",
      }}
    >
      <Container>
        <Row
          sx={{
            flexDirection: leftPos ? "row" : "row-reverse",
            flexWrap: "wrap-reverse",
          }}
        >
          <Column
            sx={{
              minWidth: ["100%", "100%", "100%", "auto"],
            }}
          >
            <Slider
              className={"portfolio-slide"}
              sx={{
                position: ["relative", "relative", "relative", "absolute"],
                left: leftPos ? 0 : "initial",
                right: !leftPos ? 0 : "initial",
                overflow: "hidden",
                width: ["auto", "auto", "auto", "50%"],
                height: "100%",
                mx: [-5, -7, -8, 0],
                backgroundColor: "black",
              }}
              {...sliderSettings}
            >
              {gallery.map(({ originalId, fluid }) => (
                <Image
                  sx={{ m: 0, height: "100%" }}
                  key={originalId}
                  fluid={fluid}
                />
              ))}
            </Slider>
          </Column>
          <Column>
            <Row
              sx={{
                py: [8, 7, 7, 9],
                pr: !leftPos ? [0, 0, 0, 5, 9] : 0,
                pl: leftPos ? [0, 0, 0, 5, 9] : 0,
                alignItems: ["center", "center", "center", "flex-start"],
                flexDirection: "row",
              }}
            >
              <Column
                sx={{
                  minWidth: ["auto", "auto", "auto", "100%"],
                  justifyContent: "center",
                }}
              >
                <Image
                  sx={{
                    width: "132px",
                    mb: [6, 6, 0, 6],
                    mr: [5, 7, "auto", 0],
                    ml: [0, 0, "auto", 0],
                  }}
                  fluid={fluid}
                />
              </Column>
              <Column sx={{ minWidth: ["auto", "auto", "auto", "100%"] }}>
                <h3 sx={{ fontWeight: "bold", mb: 2 }}>{productLine}</h3>
                <h2 className={"large"}>{title}</h2>
                <ReactMarkdown source={description} />
              </Column>
            </Row>
          </Column>
        </Row>
      </Container>
    </Row>
  )
}

const Portfolio = ({
  anchorSlug,
  description,
  portfolioItems,
}: Props & any) => (
  <Flex
    as={"section"}
    id={anchorSlug}
    sx={{ flexDirection: "column", pt: [9, 9, 6, 9] }}
  >
    <Container>
      <Row sx={{ mb: 5 }}>
        <Column>
          <ReactMarkdown source={description} />
        </Column>
        <Column />
      </Row>
    </Container>
    <FullContainer>
      {portfolioItems.map(({ id, ...item }, index) => (
        <PortfolioItem key={id} index={index} {...item} />
      ))}
    </FullContainer>
  </Flex>
)

export default Portfolio
