/** @jsx jsx */
import { Fragment, lazy, Suspense } from "react"
import Layout from "components/layout"
import Expandable from "components/sections/expandable"
import File from "components/sections/file"
import Hero from "components/sections/hero"
import ImagesText from "components/sections/images-text"
import IntellectualProperties from "components/sections/intellectual-properties"
import Portfolio from "components/sections/portfolio"
import ProductsBlock from "components/sections/products-block"
import SEO from "components/seo"
import { graphql } from "gatsby"
import { jsx } from "theme-ui"
import VideosGallery from "components/sections/videos-gallery"
const Contact = lazy(() => import("components/sections/contact"))

const Home = ({ data }) => {
  const isSSR = typeof window === "undefined"
  const {
    allDatoCmsHome: { nodes },
  } = data
  const { modules, seoMetaTags } = nodes[0]

  seoMetaTags.tags = seoMetaTags.tags.map(tag => {
    if (!!tag.attributes && /og:type/.test(tag.attributes.property)) {
      tag.attributes.content = "website"
    }

    return tag
  })

  const title = seoMetaTags.tags.find(
    t => !!t.tagName && /title/.test(t.tagName)
  )

  return (
    <Layout>
      <SEO datoMetaTags={seoMetaTags}>
        <link
          rel={"alternate"}
          href={"https://www.speeedies.eu/de/"}
          hrefLang={"de"}
        />
        <link
          rel={"alternate"}
          href={"https://www.speeedies.eu/fr/"}
          hrefLang={"fr"}
        />
        <meta property="og:site name" content={title.content} />
      </SEO>
      {modules.map(({ id, model, ...module }) => {
        switch (model.name) {
          case "Hero":
            return <Hero key={id} {...module} />
          case "Expandable":
            return <Expandable key={id} {...module} />
          case "Products block":
            return <ProductsBlock key={id} {...module} />
          case "Images text":
            return <ImagesText key={id} {...module} />
          case "Videos gallery":
            return <VideosGallery key={id} {...module} />
          case "Intellectual property block":
            return <IntellectualProperties key={id} {...module} />
          case "Portfolio block":
            return <Portfolio key={id} {...module} />
          case "Contact":
            if (isSSR) {
              return null
            }

            return (
              <Suspense key={id} fallback={<p>Loading</p>}>
                <Contact {...module} />
              </Suspense>
            )
          case "File section":
            return <File key={id} {...module} />
          default:
            return <Fragment key={id} />
        }
      })}
    </Layout>
  )
}

export const pageQuery = graphql`
  query HomePageQuery($language: String!) {
    allDatoCmsHome(filter: { locale: { eq: $language } }) {
      nodes {
        locale
        modules {
          ... on DatoCmsHero {
            id
            anchorSlug
            title
            backgroundVideo {
              url
            }
            model {
              name
            }
          }
          ... on DatoCmsExpandable {
            id
            anchorSlug
            visibleText
            hiddenText
            hiddenImage {
              title
              alt
              fluid(
                maxWidth: 400
                imgixParams: { fm: "png", auto: "compress" }
              ) {
                ...GatsbyDatoCmsFluid
              }
            }
            model {
              name
            }
          }
          ... on DatoCmsProductsBlock {
            id
            anchorSlug
            products {
              id
              position
              title
              productLine
              description
              image {
                title
                alt
                fluid(
                  maxWidth: 400
                  imgixParams: { fm: "png", auto: "compress" }
                ) {
                  ...GatsbyDatoCmsFluid
                }
              }
              articles {
                id
                articleName
                articleNumber
              }
            }
            model {
              name
            }
          }
          ... on DatoCmsImagesText {
            id
            text
            image {
              title
              alt
              fluid(
                maxWidth: 800
                imgixParams: { fm: "jpg", auto: "compress" }
              ) {
                ...GatsbyDatoCmsFluid
              }
            }
            model {
              name
            }
          }
          ... on DatoCmsVideosGallery {
            id
            anchorSlug
            description
            externalVideos {
              id
              videoUrl {
                url
                title
                provider
                providerUid
                thumbnailUrl
                width
                height
              }
            }
            model {
              name
            }
          }
          ... on DatoCmsIntellectualPropertyBlock {
            id
            anchorSlug
            description
            leftColumnTitle
            leftColumnItems {
              id
              countries
              patents
            }
            rightColumnTitle
            rightColumnItems {
              id
              countries
              patents
            }
            model {
              name
            }
          }
          ... on DatoCmsPortfolioBlock {
            id
            anchorSlug
            description
            model {
              name
            }
            portfolioItems {
              id
              productLine
              title
              description
              portfolioImage {
                title
                alt
                fluid(
                  maxWidth: 800
                  imgixParams: { fm: "svg", auto: "compress" }
                ) {
                  ...GatsbyDatoCmsFluid
                }
              }
              gallery {
                originalId
                title
                alt
                fluid(
                  maxWidth: 1000
                  imgixParams: { fm: "jpg", auto: "compress" }
                ) {
                  ...GatsbyDatoCmsFluid
                }
              }
            }
          }
          ... on DatoCmsContact {
            id
            anchorSlug
            contactFormDescription
            mapLink
            mapImage {
              originalId
              title
              alt
              fluid(
                maxWidth: 1000
                imgixParams: { fm: "jpg", auto: "compress" }
              ) {
                ...GatsbyDatoCmsFluid
              }
            }
            model {
              name
            }
          }
          ... on DatoCmsFileSection {
            id
            anchorSlug
            leftColumn
            fileDescription
            downloadButton
            file {
              url
              mimeType
              size
            }
            model {
              name
            }
          }
        }
        seoMetaTags {
          id
          tags
        }
      }
    }
  }
`

export default Home
