/** @jsx jsx */
import { Column, Row } from "components/layout"
import { FluidObject } from "gatsby-image"
import Img from "gatsby-image/withIEPolyfill"
import { useState } from "react"
import ReactMarkdown from "react-markdown"
import { animated, config, useSpring } from "react-spring/web.cjs"
import VisibilitySensor from "react-visibility-sensor"
import { Container, Flex, jsx } from "theme-ui"
import { Media } from "utils/media"

interface Image {
  originalId: string
  title: string
  alt: string
  fluid: FluidObject
}

interface Props {
  anchorSlug: string
  text: string
  image: Image
}

const ImagesText = ({ anchorSlug, text, image }: Props & any) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const { opacity, transform } = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? "translate3d(0,0,0)" : "translate3d(0,-30px,0)",
    config: config.molasses,
  })

  return (
    <Flex
      as={"section"}
      id={anchorSlug}
      sx={{ backgroundColor: "primary", position: "relative" }}
    >
      <Media greaterThanOrEqual={"sm"}>
        <Img
          alt={image.alt}
          title={image.title}
          fluid={image.fluid}
          sx={{
            mx: [-5, -7, -5, -5],
            position: "absolute !important" as any,
            left: 5,
            height: "100%",
            width: "50%",
          }}
        />
      </Media>
      <Container>
        <Row sx={{ alignItems: "center" }}>
          <Column>
            <Media lessThan={"sm"}>
              <Img
                alt={image.alt}
                title={image.title}
                fluid={image.fluid}
                sx={{ mx: [-5, -7] }}
              />
            </Media>
          </Column>
          <Column
            sx={{
              pl: [5, 5, 7, 7, 9],
            }}
          >
            <VisibilitySensor
              onChange={visibility =>
                !isVisible ? setIsVisible(visibility) : null
              }
              partialVisibility
              offset={{
                bottom: 300,
              }}
            >
              <animated.div
                style={{
                  opacity: opacity as any,
                  transform,
                }}
              >
                <ReactMarkdown sx={{ color: "white", my: 9 }} source={text} />
              </animated.div>
            </VisibilitySensor>
          </Column>
        </Row>
      </Container>
    </Flex>
  )
}

export default ImagesText
