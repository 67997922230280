/** @jsx jsx */
import { ResizeObserver } from "@juggle/resize-observer"
import { ShowMore } from "components/buttons"
import { Column, Row } from "components/layout"
import { FluidObject } from "gatsby-image"
import Img from "gatsby-image/withIEPolyfill"
import { throttle } from "utils/helpers"
import { useCallback, useEffect, useRef, useState } from "react"
import ReactMarkdown from "react-markdown"
import { animated, config, useSpring } from "react-spring/web.cjs"
import useMeasure from "react-use-measure"
import { Shape } from "styles/svg"
import { Container, Flex, jsx } from "theme-ui"

interface Props {
  anchorSlug: string
  visibleText: string
  hiddenText: string
  hiddenImage?: {
    alt: string
    title: string
    fluid: FluidObject
  }
}

const Expandable = ({
  anchorSlug,
  visibleText,
  hiddenText,
  hiddenImage,
}: Props & any) => {
  const [collapsed, setCollapsed] = useState<boolean>(true)
  const [ref, { height }] = useMeasure({ polyfill: ResizeObserver })
  const containerRef = useRef<any>()

  const [{ transform }, set, stop] = useSpring(() => ({
    transform: `translate3d(0px,0px,0) rotate(0deg)`,
    config: config.molasses,
  }))

  const onScroll = useCallback(() => {
    if (!containerRef.current) {
      return
    }

    const topPos =
      containerRef.current.getBoundingClientRect().top +
      document.documentElement.scrollTop
    const bottomScroll = window.scrollY + window.innerHeight
    let scroll = (bottomScroll - topPos) * 0.008
    scroll = scroll <= 0 ? 0 : scroll

    set({
      transform: `translate3d(${scroll * -2}px,${scroll}px,0) rotate(${
        scroll * 0.3
      }deg)`,
    })
  }, [set])

  useEffect(() => {
    window.addEventListener("scroll", throttle(onScroll, 16))

    return () => {
      window.removeEventListener("scroll", throttle(onScroll, 16))
      stop()
    }
  }, [onScroll, set, stop])

  return (
    <Flex
      id={anchorSlug}
      as={"section"}
      ref={containerRef}
      sx={{
        py: 9,
        minHeight: "60vh",
        alignItems: "center",
        borderBottom: "3px solid",
        borderBottomColor: "primary",
        position: "relative",
        overflow: "hidden",
        backgroundColor: [
          "rgba(255,255,255,0.9)",
          "rgba(255,255,255,0.9)",
          "rgba(255,255,255,0.9)",
          "transparent",
        ],
      }}
    >
      <Container>
        <Row>
          <Column sx={{ maxWidth: ["100%", "100%", "100%", "50%"] }}>
            <ReactMarkdown source={visibleText} sx={{}} />
          </Column>
        </Row>
        <animated.div
          sx={{
            overflow: "hidden",
            mx: -5,
          }}
          style={useSpring({
            height: collapsed ? 0 : height,
          })}
        >
          <Row
            ref={ref}
            sx={{
              pt: 6,
              px: 5,
              flexWrap: "wrap-reverse",
            }}
          >
            <Column>
              <ReactMarkdown source={hiddenText} />
            </Column>
            <Column sx={{ mx: -5 }}>
              <Img sx={{ mb: 5 }} fluid={hiddenImage.fluid} />
            </Column>
          </Row>
        </animated.div>
        <Row>
          <Column>
            <ShowMore onClick={() => setCollapsed(!collapsed)} />
          </Column>
        </Row>
      </Container>
      <animated.div
        style={{ transform }}
        sx={{
          zIndex: -1,
          position: "absolute",
          top: ["30px", "330px", "200px", "-40px"],
          right: ["-170px", "-220px", "-310px", "-450px"],
          width: ["350px", "440px", "500px", "800px"],
          height: ["350px", "440px", "500px", "800px"],
        }}
      >
        <Shape
          fill="#19AF5D"
          sx={{
            width: ["350px", "440px", "500px", "800px"],
            height: ["350px", "440px", "500px", "800px"],
          }}
        />
      </animated.div>
    </Flex>
  )
}

export default Expandable
