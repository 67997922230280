/** @jsx jsx */
import { DownloadButton } from "components/buttons"
import { Column, Row } from "components/layout"
import ReactMarkdown from "react-markdown"
import { FileIcon } from "styles/svg"
import { Container, Flex, jsx } from "theme-ui"

interface Props {
  anchorSlug: string
  leftColumn: string
  fileDescription: string
  downloadButton: string
  file: {
    url: string
    mimeType: string
    size: number
  }
}

const File = ({
  leftColumn,
  fileDescription,
  downloadButton,
  file,
}: Props & any) => {
  return (
    <Flex as={"section"} sx={{ backgroundColor: "light", py: 9 }}>
      <Container>
        <Row>
          <Column
            sx={{
              mb: [7, 7, 0],
            }}
          >
            <ReactMarkdown source={leftColumn} />
          </Column>
          <Column>
            <Flex sx={{ alignItems: "start" }}>
              <ReactMarkdown
                sx={{ flexDirection: "column", mr: 3, flex: 6 }}
                source={fileDescription}
              />
              <FileIcon
                sx={{
                  flex: 1,
                  fill: "text",
                  width: "100%",
                  minWidth: "55px",
                }}
              />
            </Flex>
            <a
              href={file.url}
              target={"_blank"}
              rel={"noreferrer"}
              sx={{ textDecoration: "none" }}
            >
              <DownloadButton>{downloadButton}</DownloadButton>
            </a>
          </Column>
        </Row>
      </Container>
    </Flex>
  )
}

export default File
