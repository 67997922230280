/** @jsx jsx */
import styled from "@emotion/styled"
import { ResizeObserver } from "@juggle/resize-observer"
import classNames from "classnames"
import { ShowMore } from "components/buttons"
import { Column, Row } from "components/layout"
import { useState } from "react"
import ReactMarkdown from "react-markdown"
import { animated, config, useSpring } from "react-spring/web.cjs"
import useMeasure from "react-use-measure"
import { Container, Flex, jsx } from "theme-ui"

const FadeBottom = styled.span`
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(245, 245, 245, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(245, 245, 245, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(245, 245, 245, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#f5f5f5",GradientType=1);

  opacity: 1;
  transition: 0ms ease-in-out opacity 0ms;
  &.open {
    opacity: 0;
    transition: 1500ms ease-in-out opacity 500ms;
  }
`

interface IPItem {
  id: string
  countries: string
  patents: string
}

interface Props {
  id: string
  anchorSlug: string
  description: string
  leftColumnTitle: string
  leftColumnItems: IPItem[]
  rightColumnTitle: string
  rightColumnItems: IPItem[]
}

const ColumnItem = ({ countries, patents }: IPItem & any) => (
  <div sx={{ mb: 6 }}>
    <h5 sx={{ fontWeight: "bold", mb: 0 }}>{countries}</h5>
    <ReactMarkdown
      sx={{ p: { mb: 0 } }}
      source={patents.replace(/\n/g, "\n\n")}
    />
  </div>
)

const IntellectualProperties = ({
  anchorSlug,
  description,
  leftColumnTitle,
  leftColumnItems,
  rightColumnTitle,
  rightColumnItems,
}: Props & any) => {
  const [expanded, setExpanded] = useState(false)
  const [ref, { height }] = useMeasure({ polyfill: ResizeObserver })

  return (
    <Flex
      id={anchorSlug}
      as={"section"}
      sx={{ py: [0, 0, 0, 10], overflow: "hidden" }}
    >
      <Container>
        <Flex
          sx={{
            backgroundColor: "light",
            flexDirection: "column",
            mx: [-5, -7, -8, 0],
            px: [5, 7, 8, 9],
            pt: 9,
            pb: 7,
          }}
        >
          <Row sx={{ mb: 7, width: ["100%", "100%", "100%", "70%"] }}>
            <Column>
              <ReactMarkdown source={description} />
            </Column>
          </Row>
          <animated.div
            style={useSpring({
              height: expanded ? height : 250,
              config: config.slow,
            })}
            sx={{
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Row ref={ref}>
              <Column>
                <h4 sx={{ mb: 7, fontWeight: "bold" }}>{leftColumnTitle}</h4>
                {leftColumnItems.map(({ id, ...item }) => (
                  <ColumnItem key={id} {...item} />
                ))}
              </Column>
              <Column>
                <h4 sx={{ mb: 7, fontWeight: "bold" }}>{rightColumnTitle}</h4>
                {rightColumnItems.map(({ id, ...item }) => (
                  <ColumnItem key={id} {...item} />
                ))}
              </Column>
              <FadeBottom
                className={classNames({ open: expanded })}
                sx={{
                  position: "absolute",
                  bottom: -1,
                  left: 0,
                  width: "100%",
                  height: "100px",
                }}
              />
            </Row>
          </animated.div>
          <Row>
            <Column>
              <ShowMore onClick={() => setExpanded(!expanded)} showArrow />
            </Column>
          </Row>
        </Flex>
      </Container>
    </Flex>
  )
}

export default IntellectualProperties
