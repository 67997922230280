/** @jsx jsx */
import { Column, Row } from "components/layout"
import { FluidObject } from "gatsby-image"
import Img from "gatsby-image/withIEPolyfill"
import { useState } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import ReactMarkdown from "react-markdown"
import { animated, useSpring } from "react-spring/web.cjs"
import VisibilitySensor from "react-visibility-sensor"
import { Container, Flex, jsx } from "theme-ui"
import { Media } from "utils/media"

interface Article {
  id: string
  articleName: string
  articleNumber: string
}

interface Product {
  id: string
  position: number
  title: string
  productLine: string
  description: string
  articles?: Article[]
  image?: {
    alt: string
    title: string
    fluid: FluidObject
  }
}

interface Props {
  anchorSlug: string
  products: Product[]
}

const ProductItem = ({
  image,
  productLine,
  title,
  description,
  position,
  articles,
}: Product) => {
  const [isVisible, setIsVisible] = useState(false)
  const { t } = useTranslation()

  const { opacity, transform } = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? "translate3d(0,0,0)" : "translate3d(0,10px,0)",
    config: {
      duration: 400,
    },
  })

  return (
    <Flex
      sx={{ backgroundColor: position % 2 === 0 ? "light" : "white", py: 8 }}
    >
      <VisibilitySensor
        onChange={visibility => (isVisible ? null : setIsVisible(visibility))}
        partialVisibility
        offset={{
          bottom: 300,
        }}
      >
        <Container>
          <Row
            sx={{
              flexDirection: position % 2 ? "row" : "row-reverse",
              alignItems: "center",
            }}
          >
            <Column>
              <h3 sx={{ fontWeight: "bold", mb: 2 }}>{productLine}</h3>
              <h2 className={"large"}>{title}</h2>
              <Media lessThan={"sm"}>
                <animated.div
                  style={{
                    opacity: opacity as any,
                    transform: transform as any,
                  }}
                >
                  <Img
                    alt={image.alt}
                    title={image.title}
                    fluid={image.fluid}
                    sx={{
                      maxWidth: "300px",
                      mx: "auto",
                      mt: 7,
                    }}
                  />
                </animated.div>
              </Media>
              <ReactMarkdown source={description} />
              {!!articles.length && (
                <table
                  sx={{
                    "th,td": {
                      border: "none",
                      p: 0,
                      pr: 3,
                      verticalAlign: "top",
                    },
                    td: {
                      pb: 1,
                      fontSize: 1,
                      fontFeatureSettings: "initial",
                    },
                    width: "100%",
                  }}
                >
                  <colgroup>
                    <col span={1} style={{ width: "25%" }} />
                    <col span={1} style={{ width: "75%" }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>
                        <h5 sx={{ fontSize: 1, fontWeight: "bold", mb: 3 }}>
                          {t("Article no:")}
                        </h5>
                      </th>
                      <th>
                        <h5 sx={{ fontSize: 1, fontWeight: "bold", mb: 3 }}>
                          {t("Article name:")}
                        </h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {articles.map(({ id, articleNumber, articleName }) => (
                      <tr key={`name-${id}`} sx={{ fontSize: [1], mb: 1 }}>
                        <td>{articleNumber}</td>
                        <td>{articleName}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </Column>
            <Column>
              <Media greaterThanOrEqual={"sm"}>
                <animated.div
                  style={{
                    opacity: opacity as any,
                    transform: transform as any,
                  }}
                >
                  <Img
                    alt={image.alt}
                    title={image.title}
                    fluid={image.fluid}
                    sx={{
                      maxWidth: "400px",
                      mx: "auto",
                    }}
                  />
                </animated.div>
              </Media>
            </Column>
          </Row>
        </Container>
      </VisibilitySensor>
    </Flex>
  )
}

const ProductsBlock = ({ anchorSlug, products }: Props & any) => (
  <Flex id={anchorSlug} as={"section"} sx={{ flexDirection: "column" }}>
    {products.map((product, index) => (
      <ProductItem key={product.id} {...product} position={index} />
    ))}
  </Flex>
)

export default ProductsBlock
