/** @jsx jsx */
import { useCallback, useEffect, useState } from "react"
import ReactPlayer from "react-player/lazy"
import { Flex, jsx } from "theme-ui"
import { ExternalVideo } from "."

const VideoSlide = ({ videoUrl, inFocus, onClick }: ExternalVideo & any) => {
  const [playing, setPlaying] = useState<boolean>(false)
  const [srcUrl, setSrcUrl] = useState<string>(null)

  useEffect(() => {
    setTimeout(() => {
      setSrcUrl(videoUrl.url)
    }, 1000)
  }, [videoUrl])

  const togglePlay = useCallback(() => {
    if (inFocus) {
      setPlaying(!playing)
    }
  }, [inFocus, playing])

  useEffect(() => {
    if (!inFocus && playing) {
      setPlaying(false)
    }
  }, [inFocus, playing])

  return (
    <Flex
      sx={{
        minWidth: "100%",
        height: ["180px", "270px", "426px", "510px", "510px", "546px"],
        px: 5,
        cursor: "pointer",
        ":active": {
          cursor: "grabbing",
        },
      }}
      onClick={() => {
        if (inFocus) {
          setPlaying(true)
        }
        onClick()
      }}
    >
      {!!srcUrl && (
        <ReactPlayer
          url={srcUrl}
          style={{
            backgroundColor: "black",
            pointerEvents: playing ? "initial" : "none",
            boxShadow: "0 49.5px 70px -24.5px rgba(0,0,0,0.3)",
          }}
          width="100%"
          height="100%"
          playing={playing}
          controls
          onPause={togglePlay}
          onEnded={() => setPlaying(false)}
        />
      )}
    </Flex>
  )
}

export default VideoSlide
